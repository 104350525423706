import React from "react";
import { Link } from "react-router-dom";

const AboutPage = ({ language }) => {
  return (
    <div className="container">
      <section
        className="container "
        style={{
          fontFamily: "Bahnschrift",
        }}
      >
        <div className="row">
          <div className="col-md-8 order-2 order-md-1">
            <h1
              className="mb-3"
              style={{ fontWeight: "700", color: "#90A8D0" }}
            >
              {language === "EN" ? "About SEF" : "À propos de SEF"}
            </h1>

            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN" ? "Our Story" : "Notre Histoire"}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `Since its founding in 1978, SEF has been dedicated to a noble
              mission: providing a wide range of services to widows and orphans
              who have tragically lost their fathers in war. SEF has diligently
              worked to provide them with the support they need in the various
              aspects of their lives, including medical assistance, education,
              and access to food, all in the comforting environment of their own
              homes.`
                : `Depuis sa fondation en 1978, SEF s'est consacré à une noble mission : fournir un large éventail de services aux veuves et aux orphelins qui ont tragiquement perdu leur père pendant la guerre.
              SEF a travaillé avec diligence pour leur fournir le soutien dont ils ont besoin dans les différents aspects de leur vie, y compris l'assistance médicale, l'éducation et l'accès à la nourriture; le tout dans un cadre réconfortant de leur propre maison.
              `}
              <br></br>
              <br></br>
              {language === "EN"
                ? `Within SEF's operations, there are numerous examples of best
              practices that contribute to the mission. These practices
              encompass multiple levels of support and address important areas
              such as life transitions, mental health, personal development,
              special education, housing, social connections, active engagement
              with the environment, single parenting, and professional futures.
              By focusing on these essential aspects of human life, SEF aims to
              create an environment that provides a sense of security for the
              widowed and orphaned people it serves, helping them to rebuild
              their lives and find stability and happiness.`
                : `Dans le cadre des opérations du SEF, il existe de nombreux exemples de meilleures pratiques qui contribuent à la mission. Ces pratiques englobent de multiples niveaux de soutien et abordent des domaines importants tels que les transitions de vie, la santé mentale, le développement personnel, l'éducation spéciale, le logement, les liens sociaux, l'engagement actif avec l'environnement, la monoparentalité et l'avenir professionnel. En se concentrant sur ces aspects essentiels de la vie humaine, SEF vise à créer un cadre qui procure un sentiment de sécurité aux veuves et aux orphelins qu’il héberge chez lui, en les aidant à reconstruire leur propre vie et à retrouver la stabilité et le bonheur.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `In response to the urgent needs identified through
              extensive research from 2012 to 2015, SEF embarked on a new
              mission to address a critical problem in Lebanon. It became
              evident that open shelters were urgently required to support and
              provide comprehensive services to children and their mothers who
              were victims of domestic violence, regardless of their social,
              religious, or political affiliation. Thus, in 2015, SEF became an
              open shelter committed to providing quality assistance to those
              affected by this pervasive problem.`
                : `En réponse aux besoins urgents identifiés grâce à des recherches approfondies depuis l’année 2012 jusqu’au 2015,  SEF s'est lancé dans une nouvelle mission visant à résoudre un problème critique au Liban. SEF est devenu évident que les refuges ouverts étaient nécessaires de toute urgence pour soutenir et fournir des services complets aux enfants et à leurs mères étant victimes de la violence, quelle que soit leur appartenance sociale, religieuse ou politique. Ainsi, en 2015, SEF est devenue un refuge ouvert engagé à fournir un accompagnement de qualité aux personnes touchées par ce problème omniprésent.`}
              <br></br>
              <br></br>

              {language === "EN"
                ? `SEF's open shelter serves as a sanctuary for children and their mothers, providing a safe and nurturing environment where they can find comfort, support, and empowerment. Over an approximate period of two years, SEF dedicates its resources and expertise to create an environment that fosters growth and healing. The organization strongly advocates for the rights of these vulnerable people to ensure their safety and facilitate their sustainable reintegration into society.`
                : `Les abris ouverts de SEF servent de sanctuaire aux enfants et à leurs mères, offrant un cadre  sûr et riche  où ils peuvent trouver le confort, le soutien et l’autonomisation. Sur une période d'environ deux ans, SEF consacre ses ressources et son expertise à la création d'un environnement favorisant la croissance et la reconstruction. L'organisation défend fortement les droits de ces personnes vulnérables afin d'assurer leur sécurité ainsi que leur faciliter une réintégration durable dans la société.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `The open shelter operated by SEF provides not only physical protection but also holistic support, encompassing emotional well-being, educational opportunities, and access to essential services. By addressing the diverse needs of children and mothers, SEF aims to break the cycle of violence, foster resilience, and enable them to rebuild their lives with dignity.`
                : `Les abris ouverts présentés par SEF offrent non seulement une protection physique, mais aussi un soutien holistique, comprenant leur  bien-être émotionnel, les possibilités d'éducation et l'accès aux services essentiels. En répondant aux divers besoins des enfants et des mères,  SEF vise à briser le cycle de la violence, à favoriser la résilience et à leur permettre de reconstruire leur vie avec dignité.`}
            </p>
          </div>
          <div className="col-md-4 mb-3 order-1 order-md-2 d-flex justify-content-center align-items-center">
            <img
              className="d-block w-100"
              src="/Assets/about06.jpeg"
              alt="First slide"
              style={{ borderRadius: "30px" }}
            />
          </div>
        </div>
      </section>
      <section
        className="container mt-5"
        style={{
          fontFamily: "Roboto",
        }}
      >
        <div className="row">
          <div className="col-md-6 mb-3  d-flex justify-content-center align-items-center">
            <img
              src="/Assets/about01.jpeg"
              style={{ borderRadius: "30px", width: "100%" }}
              alt=""
            />
          </div>
          <div className="col-md-6 d-flex flex-column  justify-content-center">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN" ? `Who We are Now` : `Qui sommes-nous`}{" "}
            </h2>
            <p className="mb-5" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `SEF is a Lebanese non-profit organization that provides quality and comprehensive services to children and their mothers suffering from domestic violence, regardless of their social, religious or political affiliation. SEF shelters, supports, and empowers children and their mothers, over a 1-year period by creating a supportive environment for growth and advocating for their rights while working towards their safe and sustainable reintegration into society.`
                : `SEF est une organisation libanaise à but non lucratif qui fournit des services de qualité et complets aux enfants et à leurs mères victimes de violence domestique, quelle que soit leur appartenance sociale, religieuse ou politique. SEF héberge, soutient et responsabilise les enfants et leurs mères, sur une période d'un an environ, en créant un environnement propice à la croissance et en défendant leurs droits tout en travaillant sur leur réintégration sûre et durable dans la société.`}
            </p>
          </div>
        </div>
      </section>
      <section
        className="container "
        style={{
          fontFamily: "Roboto",
        }}
      >
        <div className="row">
          <div className="col-md-6 d-flex flex-column  justify-content-center order-2 order-md-1">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN" ? `Our Mission` : `Notre Mission`}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `SEF’s mission is to accommodate vulnerable children and their
              mothers and provide them with long-term support without any kind
              of social, religious or political discrimination. The aim is to
              create a stable and safe environment focusing on the development
              of the mother-child relationship and the mobilization of parenting
              skills. It allows them access to their rights and the means to
              enforce them. Additionally, the service supports the creation of
              a family project and individual projects, with the goal of
              achieving autonomy through economic and social integration.`
                : `Le Service de L’Enfant Au Foyer (SEF) a pour mission d’héberger et d’accompagner, pour une période de temps, les enfants en danger et leur mères, sans aucune discrimination sociale, religieuse ou politique. 

              Il leur offre un cadre stable et sécurisant, tout en privilégiant la construction d’une relation mère-enfant,  et la mobilisation des capacités parentales. Cela leur permet l’accès à leurs droits et aux moyens de les faire valoir.
              
              En dernier temps, SEF accompagne la construction d'un projet familial et de projets individuels, visant une autonomie par l’insertion économique et sociale.
              `}
            </p>
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN" ? `Our Vision` : `Notre Vision`}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `A community where every child enjoys their rights and grows up
              with dignity in a conducive environment, free from violence.`
                : `Une communauté où chaque enfant jouit de ses droits et grandit dans la dignité dans un environnement propice, à l'abri de la violence.`}
            </p>
          </div>
          <div className="col-md-6 mb-3 order-1 order-md-2 d-flex justify-content-center align-items-center">
            <img
              src="/Assets/about03.jpeg"
              style={{ borderRadius: "30px", width: "100%" }}
              alt=""
            />
          </div>
        </div>
      </section>
      <section
        className="container mt-5"
        style={{
          fontFamily: "Roboto",
        }}
      >
        <div className="row">
          <div className="col-md-6 mb-3  d-flex justify-content-center align-items-center">
            <img
              src="/Assets/children.jpeg"
              style={{ borderRadius: "30px", width: "100%" }}
              alt=""
            />
          </div>
          <div className="col-md-6 d-flex flex-column  justify-content-center">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN" ? `Our Values` : `Nos Valeurs`}
            </h2>
            <p className="mb-5" style={{ color: "#A2979A" }}>
              <span style={{ color: "#90A8D0" }}>
                {language === "EN"
                  ? `Respect and Dignity`
                  : `Respect et dignité`}
                :
              </span>
              {language === "EN"
                ? ` We
              treat each individual with the utmost respect and dignity,
              recognizing their inherent worth and strength. We strive to create
              an inclusive and non-judgmental space where mothers feel empowered
              to make decisions for themselves and regain control of their
              lives.`
                : ` Nous traitons chaque individu avec le plus grand respect et la plus grande dignité, tout en reconnaissant sa valeur et sa force inhérentes; nous croyons au SEF que chaque personne a été faite à l’image de Dieu et est donc d’une valeur unique et digne du plus grand respect. Nous nous efforçons de créer un espace inclusif et sans jugement, où les mères se sentent habilitées à prendre leurs propres décisions et à reprendre le contrôle de leur vie.`}
              <br></br>
              <br></br>
              <span style={{ color: "#90A8D0" }}>
                {" "}
                {language === "EN"
                  ? `Safety and Security:`
                  : `Sûreté et sécurité:`}
              </span>
              {language === "EN"
                ? ` We prioritize the safety and security of the mothers and children we
              serve. We provide a safe and confidential environment where they
              can seek refuge, find solace, and rebuild their lives free from
              violence and fear.`
                : ` Nous priorisons la sûreté et  la sécurité des mères et des enfants que nous hébergeons, ainsi que nous  fournissons un cadre  stable et confidentiel où ils peuvent chercher refuge, trouver du réconfort et reconstruire leur vie sans violence, ni peur. Nous croyons que la sécurité devrait être un mode de vie et être à l’avant-garde de toute notre prise de décision.`}
              <br></br>
              <br></br>
              <span style={{ color: "#90A8D0" }}>
                {language === "EN"
                  ? ` Empowerment and Education:`
                  : `Autonomisation et éducation:`}
              </span>{" "}
              {language === "EN"
                ? `We are committed to empowering our beneficiaries by promoting
              education and awareness. Through workshops and training programs,
              we strive to raise awareness about domestic violence and equip
              people with the knowledge and skills they need to break the cycle
              of abuse.`
                : `Nous nous engageons à responsabiliser nos bénéficiaires en promouvant l'éducation et la sensibilisation. Grâce à des ateliers et à des programmes de formation, nous nous efforçons de sensibiliser contre la violence domestique et de doter les gens des connaissances et des compétences dont ils ont besoin pour briser le cycle de la violence.`}
              <br></br>
              <br></br>
              <span style={{ color: "#90A8D0" }}>
                {" "}
                {language === "EN"
                  ? `Long-term Impact:`
                  : `Impact à long terme:`}
              </span>
              {language === "EN"
                ? ` Our
              ultimate goal is to create long-term impact and social change. We
              work to build a society where domestic violence is not tolerated,
              where survivors are supported, and where every mother and child
              can live a life free from violence, fear, and oppression.`
                : ` Notre objectif ultime est de créer un impact à long terme et un changement social. Nous travaillons à construire une société où la violence domestique n'est pas tolérée, où les survivants sont soutenus et où chaque mère et chaque enfant peuvent vivre une vie libre de violence, de peur et d'oppression.`}
            </p>
            <div className="mb-5">
              <Link
                to="/programs"
                style={{
                  color: "#90A8D0",
                  fontWeight: "900",
                  display: "flex",
                  gap: "10px",
                }}
              >
                {language === "EN"
                  ? `Programs and Services `
                  : `Programmes et Services `}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-arrow-right-square"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
