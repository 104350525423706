import React, { useState } from "react";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { faDonate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../Components/Spinner";
import { callDonateApi } from "../utils/http";
import Banner from "../Components/Banner";

const DonatePage = ({ language }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [amounts, setAmounts] = useState([50, 100, 150, 250, "custom"]);
  const [selectedAmount, setSelectedAmount] = useState(null);

  const handleDonate = async (amount) => {
    if (isLoading) {
      return;
    }
    setSelectedAmount(amount);
    setIsLoading(true);

    try {
      const res = await callDonateApi(amount, `Donation of $${amount}`);

      if (res?.data) {
        const resUrl = JSON.parse(res.data);
        window.location.href = resUrl.url;
      } else {
        throw res;
      }
    } catch (err) {
      //TODO: handle error
      setSelectedAmount(null);
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        color: "white",
        minHeight: "calc(100vh - 250px)",
      }}
    >
      {/* <Banner></Banner> */}
      <section
        className="container"
        style={{
          fontFamily: "Bahnschrift",
        }}
      >
        <div className="col justify-center">
          <div
            className="col text-center justify-content-center"
            style={{
              backgroundColor: "#90A8D0",
              paddingTop: "50px",
              paddingBottom: "50px",
              borderBottomLeftRadius: "40px",
              borderBottomRightRadius: "40px",
            }}
          >
            <InView triggerOnce>
              {({ inView, ref }) => (
                <motion.div
                  initial={{ opacity: 0, scale: 0.7 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <div>
                    <FontAwesomeIcon icon={faDonate} size="2xl" />
                  </div>
                  <h2 className="mb-4 mt-4" style={{ fontWeight: "600" }}>
                    {language === "EN" ? "Donate" : "À propos de SEF"}
                  </h2>
                  <p
                    className="mb-3"
                    style={{ fontSize: 18, textAlign: "center" }}
                  >
                    {language === "EN"
                      ? `Your generous donation helps us continue our mission of making a difference in the world. Thank you for your support!`
                      : `Votre généreuse donation nous aide à poursuivre notre mission de faire une différence dans le monde. Merci pour votre soutien !`}
                  </p>
                </motion.div>
              )}
            </InView>
            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center w-100">
              {amounts.map((amount) => {
                if (amount === "custom") {
                  return (
                    <div
                      style={{ width: 250, maxWidth: 250 }}
                      class="input-group mt-3 d-flex justify-content-center align-items-center"
                    >
                      <input
                        type="number"
                        min={1}
                        minLength={1}
                        className="form-control"
                        placeholder="Other amount"
                        disabled={isLoading}
                        onChange={(e) => {
                          setSelectedAmount(e.target.value);
                        }}
                        value={selectedAmount}
                      />
                      <button
                        className={`btn btn-outline-light mr-2 d-flex flex-row justify-items-center align-items-center ${
                          isLoading ? "disabled" : ""
                        }`}
                        type="button"
                        id="button-addon1"
                        onClick={() => {
                          if (selectedAmount < 1) {
                            return;
                          }
                          handleDonate(selectedAmount);
                        }}
                      >
                        {isLoading && selectedAmount == amount ? (
                          <Spinner />
                        ) : null}
                        Donate
                      </button>
                    </div>
                  );
                  return;
                }

                return (
                  <button
                    type="button"
                    key={`amount-${amount}`}
                    onClick={() => handleDonate(amount)}
                    className={`btn btn-outline-light mx-2 mt-3 d-flex flex-row justify-items-center align-items-center ${
                      isLoading ? "disabled" : ""
                    }`}
                  >
                    {isLoading && selectedAmount == amount ? <Spinner /> : null}
                    <div className="mx-1"></div>
                    {amount}$
                  </button>
                );
              })}
            </div>
          </div>
          <div className="row" style={{marginTop:'50px', marginBottom:'50px'}}>
          <div className="col-md-6 text-center d-flex justify-content-center align-items-center" style={{ color: "#90A8D0" , fontSize:'24px', marginBottom:'30px'}}>
          {language === "EN" ? "SEF has officially become a new member on the Medco App! Your support means the world to us, and now you can redeem your points in the app by donating to SEF!" : "SEF est officiellement devenu un nouveau membre de l'application Medco ! Votre soutien nous est précieux, et maintenant vous pouvez échanger vos points dans l'application en faisant un don à SEF !"}
          </div>
          <div className="col-md-6 text-center justify-content-center">
            <video
              controls
              width="330"
              style={{ borderRadius: "60px" }}
              className="mx-auto"
            >
              <source src="/Assets/medco-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DonatePage;
